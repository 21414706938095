import { useState } from "react"
import logoSrc from "/public/icons/soem-labs-logo.svg"
import Container from "../Container"
import styles from "./styles/Header.module.scss"
import Menu from "./Menu"
import Image from "next/image"
import { useRouter } from "next/router"
import { HeaderProps } from "./types"

const Header = ({ withoutNavigation }: HeaderProps) => {
  const { pathname } = useRouter()
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <header className={`${styles.header} ${menuOpen ? styles.menuOpen : ""}`}>
      <Container>
        <div className={styles.headerInner}>
          <div className={styles.logoWrapper}>
            <a href={`${pathname === "/" ? "" : "/"}#opening-section`}>
              <Image
                loading="lazy"
                src={logoSrc}
                alt="Soem labs logo"
                width={230}
                height={40}
              />
            </a>
          </div>

          {!withoutNavigation && (
            <>
              <div className={styles.rightHandWrapper}>
                <Menu isOpen={menuOpen} close={() => setMenuOpen(false)} />
              </div>

              <button
                onClick={() => setMenuOpen(true)}
                className={styles.menuButton}
                name="Menu"
              >
                <span className={styles.menuBar} />
                <span className={styles.menuBar} />
                <span className={styles.menuBar} />
              </button>
            </>
          )}
        </div>
      </Container>
    </header>
  )
}

export default Header
