import styles from './Container.module.scss'
import { ContainerProps } from './types'

const Container = ({ children }: ContainerProps) => {
  return (
    <div className={styles.container}>
      {children}
    </div>
  )
}

export default Container