import Footer from "../Footer"
import Header from "../Header"
import { LayoutProps } from "./types"
import styles from "./Layout.module.scss"
import dynamic from "next/dynamic"
import Loader from "../Loader"
import ContactModal from "../ContactModal"
import { useState } from "react"

const CookieBanner = dynamic(() => import("../CookieBanner"), {
  loading: () => <Loader />,
})

const Layout = ({
  children,
  withoutContactModal,
  withoutHeaderNavigation,
}: LayoutProps) => {
  const [contactModalOpen, setContactModalOpen] = useState(true)

  return (
    <>
      <Header withoutNavigation={withoutHeaderNavigation} />
      <div className={styles.layout}>
        <main>{children}</main>
        <Footer />
        {!withoutContactModal && (
          <ContactModal
            isOpen={contactModalOpen}
            onClose={() => setContactModalOpen(false)}
          />
        )}
        <CookieBanner />
      </div>
    </>
  )
}

export default Layout
