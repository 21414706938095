import { UseOnClickOutside } from "@/shared/hooks/useOnClickOutside";
import { useRef } from "react";
import { useTranslation } from "next-i18next";
import logoSrc from "/public/icons/soem-labs-logo.svg";
import Button from "../../Button";
import { MenuProps } from "./types";
import styles from "../styles/Menu.module.scss";
import Image from "next/image";
import { useRouter } from "next/router";
import Link from "next/link";
import { availableLanguages } from "@/shared/constants/languages";

const Menu = ({ isOpen, close }: MenuProps) => {
  const router = useRouter();
  const { pathname, asPath, query } = router;
  const { t, i18n } = useTranslation("common");

  const ref = useRef<HTMLElement>(null);

  const handleLanguageChange = (locale: "es" | "en") => {
    router.push({ pathname, query }, asPath, { locale });
    close();
  };

  UseOnClickOutside({ ref, handler: () => close() });

  const getAnchorRoute = (hash: string) => {
    const getLocalePathname = () => {
      if (i18n.resolvedLanguage !== "en") {
        return `/${i18n.resolvedLanguage}/`;
      }
      return "/";
    };
    const newPathname = pathname === "/" ? "" : getLocalePathname();
    return `${newPathname}#${hash}`;
  };

  return (
    <nav
      className={`${styles.menu} ${isOpen ? styles.menuOpen : ""}`}
      ref={ref}
    >
      <div>
        <a href={getAnchorRoute("opening-section")} onClick={close}>
          <Image
            loading="lazy"
            src={logoSrc}
            alt="Soem labs logo"
            className={styles.logo}
            width={160}
            height={30}
          />
        </a>
        <ul className={styles.list}>
          <li className={styles.item}>
            <a
              href={getAnchorRoute("services")}
              className={`${styles.link} ${styles.linkWithoutBg}`}
              onClick={close}
            >
              {t("header.menu.services")}
            </a>
          </li>
          <li className={styles.item}>
            <a
              href={getAnchorRoute("about-us")}
              className={`${styles.link} ${styles.linkWithoutBg}`}
              onClick={close}
            >
              {t("header.menu.aboutUs")}
            </a>
          </li>
          <li className={styles.item}>
            <Link
              href="/blog"
              className={`${styles.link} ${styles.linkWithoutBg}`}
              onClick={close}
            >
              {t("header.menu.blog")}
            </Link>
          </li>
          <li className={styles.item}>
            <Button
              text={t("header.menu.contact")}
              href={getAnchorRoute("contact")}
              className={styles.link}
              onClick={close}
            />
          </li>
        </ul>
      </div>

      <div>
        <div className={styles.languageButtons}>
          {availableLanguages.map((language) => (
            <button
              key={language}
              className={`${styles.language} ${
                i18n.resolvedLanguage === language
                  ? styles.selectedLanguage
                  : ""
              }`}
              onClick={() => handleLanguageChange(language as "es" | "en")}
              name={t(`languages.${language}`) as string}
              style={{ order: i18n.resolvedLanguage === language ? 0 : 1 }}
            >
              {language.toUpperCase()}
            </button>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Menu;
