import dynamic from "next/dynamic"
import React, { useState } from "react"
import Loader from "../Loader"
import Modal, { ModalPositions } from "../Modal"
import styles from "./ContactModal.module.scss"
import { useTranslation } from "next-i18next"
import { ContactModalProps } from "./types"

const ContactForm = dynamic(() => import("@/shared/components/ContactForm"), {
  loading: () => <Loader />,
})

const ContactModal = ({
  hasBackdrop,
  position = ModalPositions.BottomRight,
  isOpen,
  onClose,
}: ContactModalProps) => {
  const { t } = useTranslation("common")

  const [submitSuccess, setSubmitSuccess] = useState(false)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      position={position}
      customZIndex={101}
      hasBackdrop={hasBackdrop}
    >
      <div className={styles.contentWrapper}>
        <h3 className={styles.title}>{t("contactModal.title")}</h3>
        {!submitSuccess && (
          <p className={styles.text}>{t("contactModal.text")}</p>
        )}
        <ContactForm
          onlyMandatoryFields
          submitButtonClassName={styles.submitButton}
          submitButtonText={t("contactModal.submitButton") as string}
          setSubmitSuccess={setSubmitSuccess}
        />
      </div>
    </Modal>
  )
}

export default ContactModal
