import { contactEmail } from "@/shared/constants/links";
import React from "react";
import { ContactLinkProps } from "./types";

const ContactLink = ({ text = contactEmail, className }: ContactLinkProps) => {
  return (
    <a href={`mailto:${contactEmail}`} className={className}>
      {text}
    </a>
  );
};

export default ContactLink;
