import { StrongTextParserProps } from "./types"

const StrongTextParser = ({ text }: StrongTextParserProps) => {
  return (
    <>
      {text
        .split("/strong/")
        .map((string, index) =>
          index % 2 === 0 ? (
            <span key={string + index}>{string}</span>
          ) : (
            <strong key={string + index}>{string}</strong>
          )
        )}
    </>
  )
}

export default StrongTextParser
