import { LoaderProps } from './types'
import styles from './Loader.module.scss'

const Loader = ({ dimensions = 50 }: LoaderProps) => {

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.loader} loader`}>
        <style jsx>
          {`
            .loader {
              width: ${dimensions}px;
              height: ${dimensions}px;
              box-shadow: 0px 0px ${dimensions / 2}px -${dimensions / 4}px black;
            }
            .loader:before {
              border: ${dimensions * 0.02}px solid white;
              box-shadow: inset 0px 0px ${dimensions / 2}px -${dimensions * 0.4}px #111;
            }
        `}
        </style>
      </div>
    </div>
  )
}

export default Loader