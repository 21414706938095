import React, { PropsWithChildren, useEffect } from "react"
import { ModalProps } from "./types"
import styles from "./Modal.module.scss"

export enum ModalPositions {
  Center = "Center",
  BottomRight = "BottomRight",
}

const Modal = ({
  children,
  position = ModalPositions.Center,
  hasBackdrop,
  isOpen,
  onClose = () => {},
  hideCloseButton,
  blockScroll,
  id,
  customZIndex,
}: PropsWithChildren<ModalProps>) => {
  useEffect(() => {
    if (isOpen && blockScroll) {
      document.body.style.overflow = "hidden"
    }
    if (!isOpen && blockScroll) {
      document.body.removeAttribute("style")
    }
  }, [isOpen, blockScroll])

  return isOpen ? (
    <>
      {hasBackdrop && <div onClick={onClose} className={styles.backdrop}></div>}

      <div
        className={`${styles.modal} ${styles[position]}`}
        role="dialog"
        id={id}
        style={{ zIndex: customZIndex || undefined }}
      >
        {!hideCloseButton && (
          <button onClick={onClose} className={styles.closeButton} />
        )}
        {children}
      </div>
    </>
  ) : null
}

export default Modal
