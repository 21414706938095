import { useTranslation } from "next-i18next";
import logoSrc from "/public/icons/soem-labs-logo-white.svg";
import linkedinSrc from "/public/icons/linkedin.svg";
import StrongTextParser from "../StrongTextParser";
import styles from "./Footer.module.scss";
import Image from "next/image";
import ContactLink from "../ContactLink";
import Container from "../Container";
import Link from "next/link";

const Footer = () => {
  const { t } = useTranslation("common");

  return (
    <footer className={styles.footer}>
      <Container>
        <nav className={styles.footerNav}>
          <div className={styles.leftWrapper}>
            <div className={styles.logoWrapper}>
              <Image
                loading="lazy"
                src={logoSrc}
                alt="Soem labs logo"
                className={styles.logo}
                width={200}
                height={40}
              />
              <p className={styles.claim}>
                <StrongTextParser text={t("footer.claim")} />
              </p>
            </div>
            <div className={styles.legalRightsWrapper}>
              <p>Soem Labs©, {new Date().getFullYear()}</p>
              <p>{t("footer.allRightsReserved")}</p>
            </div>
          </div>
          <div className={styles.rightWrapper}>
            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>{t("footer.contactUs")}</h4>
              <ul>
                <li className={styles.sectionItem}>
                  <ContactLink className={styles.email} />
                </li>
                <li className={styles.sectionItem}>
                  <Link
                    href="https://meetings.hubspot.com/soem-labs"
                    target="_blank"
                  >
                    {t("footer.bookAMeeting")}
                  </Link>
                </li>
                <li className={styles.sectionItem}>
                  <Link
                    href="https://www.linkedin.com/company/soemlabs"
                    target="_blank"
                    className={styles.linkWithImage}
                  >
                    <Image
                      loading="lazy"
                      src={linkedinSrc}
                      alt="linkdein link"
                      width={20}
                      height={20}
                    />
                    <span>LinkedIn</span>
                  </Link>
                </li>
              </ul>
            </div>

            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>{t("footer.legal")}</h4>
              <ul>
                <li className={styles.sectionItem}>
                  <Link href="/cookies-policy">
                    {t("cookieBanner.cookiesPolicy")}
                  </Link>
                </li>
              </ul>
            </div>

            <div className={styles.section}>
              <h4 className={styles.sectionTitle}>{t("footer.languages")}</h4>
              <ul>
                <li className={styles.sectionItem}>
                  <Link href="/en">{t("languages.en")}</Link>
                </li>
                <li className={styles.sectionItem}>
                  <Link href="/es">{t("languages.es")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </Container>
    </footer>
  );
};

export default Footer;
