import { MouseEventHandler } from "react";

export enum ButtonVariants {
  Primary = "primary",
  Secondary = "secondary",
  OutlinedPrimary = "outlinedPrimary",
  OutlinedSecondary = "outlinedSecondary",
  Accent = "accent",
  Borderless = "borderless",
}

export interface ButtonProps {
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  href?: string;
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  variant?: ButtonVariants;
}
