import { ButtonProps, ButtonVariants } from "./types";
import styles from "./Button.module.scss";

const Button = ({
  text,
  onClick,
  href,
  className,
  type = "button",
  disabled,
  variant = ButtonVariants.Accent,
}: ButtonProps) => {
  return href ? (
    <a
      className={`${styles.button} ${styles[variant]} ${className}`}
      href={href}
      onClick={onClick}
    >
      {text}
    </a>
  ) : (
    <button
      className={`${styles.button} ${styles[variant]} ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
      name={text}
    >
      {text}
    </button>
  );
};

export default Button;
